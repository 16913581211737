import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase';

const DemoPage = () => {
    const [courseData, setCourseData] = useState({});
    const [selectedSchool, setSelectedSchool] = useState('');
    const [selectedDept, setSelectedDept] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "courses"));
            const data = {};
            querySnapshot.forEach((doc) => {
                data[doc.id] = doc.data();
            });
            setCourseData(data);
            const normalizedData = {};
            Object.keys(data).forEach((key) => {
                const schoolData = data[key];
                Object.keys(schoolData).forEach((school) => {
                    if (!normalizedData[school]) {
                        normalizedData[school] = {};
                    }
                    Object.keys(schoolData[school]).forEach((dept) => {
                        if (!normalizedData[school][dept]) {
                            normalizedData[school][dept] = [];
                        }
                        normalizedData[school][dept] = schoolData[school][dept];
                    });
                });
            });
            setCourseData(normalizedData);
        };

        fetchData();
    }, []);

    const handleSchoolChange = (e) => {
        setSelectedSchool(e.target.value);
        setSelectedDept('');
        setSelectedCourse('');
    };

    const handleDeptChange = (e) => {
        setSelectedDept(e.target.value);
        setSelectedCourse('');
    };

    return (
        <div>
            <h2 style={{ textAlign: 'center', fontFamily: 'sans-serif', color: '#333', marginBottom: '20px' }}>Find a verified tutor for your course</h2>
            <div style={{ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>  
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: '20px', 
                    background: 'white', 
                    padding: '30px', 
                    borderRadius: '10px', 
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)', 
                    width: '100%', 
                    maxWidth: '600px' 
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <label htmlFor="school" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', color: '#333' }}>School</label>
                        <select id="school" value={selectedSchool} onChange={handleSchoolChange} style={{ padding: '8px', minWidth: '200px', border: '1px solid #ccc', borderRadius: '4px' }}>
                            <option value="">Select School</option>
                            {Object.keys(courseData).map(school => (
                                <option key={school} value={school}>{school}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <label htmlFor="dept" style={{fontFamily: 'sans-serif',  fontWeight: 'bold', color: '#333' }}>Department</label>
                        <select id="dept" value={selectedDept} onChange={handleDeptChange} disabled={!selectedSchool} style={{ padding: '8px', minWidth: '200px', border: '1px solid #ccc', borderRadius: '4px' }}>
                            <option value="">Select Department</option>
                            {selectedSchool && Object.keys(courseData[selectedSchool]).map(dept => (
                                <option key={dept} value={dept}>{dept}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <label htmlFor="course" style={{ fontFamily: 'sans-serif', fontWeight: 'bold', color: '#333' }}>Course</label>
                        <select id="course" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)} disabled={!selectedDept} style={{ padding: '8px', minWidth: '200px', border: '1px solid #ccc', borderRadius: '4px' }}>
                            <option value="">Select Course</option>
                            {selectedDept && courseData[selectedSchool] && courseData[selectedSchool][selectedDept] && courseData[selectedSchool][selectedDept].map(course => (
                                <option key={course} value={course}>{course}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <button 
                            style={{ 
                                padding: '10px 20px', 
                                backgroundColor: '#4CAF50', 
                                color: 'white', 
                                border: 'none', 
                                borderRadius: '4px', 
                                cursor: 'pointer', 
                                display: 'flex', 
                                alignItems: 'center',
                                gap: '5px',
                                fontFamily: 'sans-serif',
                            }}
                            onClick={() => alert('Finding tutors...')}
                        >
                            Find Tutors!
                            <span style={{ fontSize: '16px', fontWeight: 'bold' }}>&rarr;</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default DemoPage;