import React, { useState, useEffect } from 'react';
import { db, storage } from './firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { serverTimestamp, collection, addDoc, getDocs } from 'firebase/firestore';

const TutorForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [university, setUniversity] = useState('');
    const [courses, setCourses] = useState('');
    const [status, setStatus] = useState('');
    const [file, setFile] = useState(null);

    const [docCount, setDocCount] = useState(0);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('submissionMade') === 'true') {
            setSubmitted(true);
        }
    }, []);

    useEffect(() => {
        const fetchDocumentCount = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'landing'));
                setDocCount(querySnapshot.size);
            } catch (error) {
                console.error('Error fetching document count:', error);
            }
        };
        fetchDocumentCount();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const fileInput = file;

            if (docCount >= 100) {
                alert('Sorry, we have reached the maximum number of submissions.');
                return;
            };

            // Check file size (2MB limit)
            if (fileInput.size > 2 * 1024 * 1024) {
                alert('File size must be less than 2MB. Try saving in black and white or a lower resolution.');
                setFile(null); // Clear the file input
                return;
            }
            if (localStorage.getItem('submissionMade')) {
            alert('You have already submitted your information.');
            return;
            }

            const fileExtension = fileInput.name.split('.').pop();
            const fileNameWithoutExtension = fileInput.name.replace(`.${fileExtension}`, '');
            const uniqueFileName = `${fileNameWithoutExtension}_${Math.random().toString(36).substring(2, 6)}.${fileExtension}`;
            const storageRef = ref(storage, `transcript-uploads/${uniqueFileName}`);
            const snapshot = await uploadBytes(storageRef, fileInput);
            
            // Get the file's URL after upload
            const fileURL = await getDownloadURL(snapshot.ref);
            console.log('File URL:', fileURL);

            const docRef = await addDoc(collection(db, 'landing'), {
                name,
                email,
                university,
                courses,
                status,
                file: fileURL,
                timestamp: serverTimestamp()
            });
            console.log([name, email, university, courses, status, fileURL]);
            console.log(docRef);
            console.log('Submission successful');

            // Mark that a submission has been made
            localStorage.setItem('submissionMade', 'true');
            setSubmitted(true);
        } catch (error) {
            console.error('Error submitting form: ', error);
        }
    };

    if (submitted) {
        // return a message in the form-container box with small font size
        return (
            <div className='form-container'>
                <p style={{ fontSize: '16px' }}>Thank you for your submission! We will be in touch.</p>
                <button 
                style={{ 
                    fontSize: '10px', padding: '5px', 
                    borderRadius: '5px', border: 'none', 
                    backgroundColor: '#f0f0f0', cursor: 'pointer',
                    position: 'fixed', bottom: '10px', right: '10px'
                }}
                onClick={() => setSubmitted(false)}
            >
                &#x21bb;
            </button>
            </div>
        );
    }

    return (
        <div>
        <form onSubmit={handleSubmit} className='form-container'>

            <p style={{fontSize: '16px', textAlign: 'left', lineHeight: '1.4'}}>Interested in finding new students to tutor? Share your info, 
                a recent transcript verifying your enrollment status (unoffical is fine), and the courses you have experience tutoring.</p>
            <p style={{fontSize: '16px', textAlign: 'left', lineHeight: '1.4'}}>Your info stays private. No spam, we promise!</p>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px', width: '100%' }}>
                <label style={{ flex: '0 0 20%', marginRight: '10px', textAlign: 'left' }}>Name:</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Oski Bear'
                    required
                    className="form-input"
                />
            </div>

            <div className='form-group'>
                <label style={{ flex: '0 0 20%', marginRight: '10px', textAlign: 'left' }}>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    placeholder='oski@berkeley.edu'
                    className="form-input"
                />
            </div>

            <div className='form-group'>
                <label style={{ flex: '0 0 20%', marginRight: '10px', textAlign: 'left' }}>University:</label>
                <input
                    type="text"
                    value={university}
                    onChange={(e) => setUniversity(e.target.value)}
                    required
                    placeholder='UC Berkeley'
                    className="form-input"
                />
            </div>

            <div className='form-group'>
                <label style={{ flex: '0 0 20%', marginRight: '10px', textAlign: 'left' }}>Transcript:</label>
                <input
                    type="file"
                    onChange={handleFileChange}
                    className="form-input"
                />
            </div>

            <div className='form-group'>
                <label style={{ flex: '0 0 20%', marginRight: '10px', textAlign: 'left' }}>Status:</label>
                <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    required
                    className="form-input"
                >
                    <option value="" disabled>Select your student status</option>
                    <option value="undergraduate">Undergraduate</option>
                    <option value="masters">Master's</option>
                    <option value="doctorate">Doctoral</option>
                </select>
            </div>

            <div className='form-group'>
                <label style={{ flex: '0 0 20%', marginRight: '10px', textAlign: 'left' }}>Courses:</label>
                <input
                    type="text"
                    value={courses}
                    onChange={(e) => setCourses(e.target.value)}
                    required
                    placeholder='ECON 100A, MATH 1A'
                    className="form-input"
                />
            </div>
            <button type="submit" style={{ width: '100%', padding: '10px', borderRadius: '5px', border: 'none', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold', cursor: 'pointer', boxSizing: 'border-box' }}>Submit</button>
            </form>
        </div>
    );
};

function VerificationPage() {
    useEffect(() => {
        document.title = 'Verification';
    }, []);

    return (
        <div className="App">
            <header className="App-header">
                <h2 style={{ fontFamily: 'Lora, serif' }}>Tutor Verification</h2>
                <TutorForm />
            </header>
        </div>
    );
}

export default VerificationPage;