import logo from './logo.svg';
import './App.css';
import { Widget } from '@typeform/embed-react'
import { createWidget } from '@typeform/embed'
import React, { useState, useEffect } from 'react';
//import '@typeform/embed/build/css/widget.css'
import { db, storage } from './firebase'; // Import the Firestore database

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { serverTimestamp, collection, addDoc, getDocs } from 'firebase/firestore';
// import '@fontsource/lora'; // Defaults to weight 400.
import '@fontsource/lora/600.css'; // Include a specific weight if needed.
import '@fontsource/open-sans';



//const tyepform = () => {
//	  return <Widget id="p62oDejO" style={{ width: '50%' }} className="my-form" />
//}


// createWidget('p62oDejO', {
//     container: document.querySelector('#form'), // you need an element with 'form' id
//     hideHeaders: true,
//     hideFooter: true,
//     opacity: 0,
// })


const Survey = () => {
    const [height, setHeight] = useState(window.innerWidth < 620 ? '300px' : '600px');
    
    useEffect(() => {
        function handleResize() {
            setHeight(window.innerWidth < 620 ? '300px' : '600px');
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <Widget
            id='p62oDejO'
            style={{
                width: '60%',
                height: height,
                margin: '20px auto',
            }}
            medium="demo-test"
        />
    );
};


const InterestForm = () => {
    const [email, setEmail] = useState('');
    const [docCount, setDocCount] = useState(0);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (localStorage.getItem('submissionMade') === 'true') {
            setSubmitted(true);
        }
    }, []);

    useEffect(() => {
        const fetchDocumentCount = async () => {
            try {
                const querySnapshot = await getDocs(collection(db, 'interest'));
                setDocCount(querySnapshot.size);
            } catch (error) {
                console.error('Error fetching document count:', error);
            }
        };
        fetchDocumentCount();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (docCount >= 1000) {
                alert('Sorry, we have reached the maximum number of submissions.');
                return;
            };

            const docRef = await addDoc(collection(db, 'interest'), {
                email,
                timestamp: serverTimestamp()
            });
            console.log('Submission successful', docRef);
            setEmail('');
            // Mark that a submission has been made
            localStorage.setItem('submissionMade', 'true');
            setSubmitted(true);
        } catch (error) {
            console.error('Error submitting form: ', error);
        }
    };

    if (submitted) {
        // return a message in the form-container box with small font size
        return (
            <div>
                <p style={{ fontSize: '16px' }}>Thank you for your interest! We will be in touch.</p>
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit} className='interest-form' style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ position: 'relative', flex: 1 }}>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Enter email'
                    required
                    style={{
                        width: '100%',
                        padding: '10px 40px 10px 10px',
                        borderRadius: '20px',
                        border: '1px solid #ccc',
                        boxSizing: 'border-box'
                    }}
                />
                <button type="submit" style={{
                    position: 'absolute',
                    right: '5px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '25px',
                    height: '25px',
                    borderRadius: '50%',
                    border: 'none',
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer'
                }}>
                    →
                </button>
            </div>
        </form>
    );
};


function HomePage() {
    useEffect(() => {
        document.title = 'Illuminate';
    }, []);

    return (
    <div className="App">
      <header className="App-header">
      <h2 style={{ fontFamily: 'Lora, serif' }}>Illuminate</h2>
      <p style={{ fontFamily: 'Lora, sans-serif', fontStyle: 'italic' }}>Connecting tutors to college students, fast.</p>
      <p style={{ fontFamily: 'Lora, sans-serif', fontStyle: 'italic' }}>Coming Soon</p>
      <p style={{ fontFamily: 'Lora, sans-serif', fontStyle: 'italic', fontSize: '18px' }}>Sign up for updates:</p>

      <InterestForm />

      </header>
    </div>
  );
}

export default HomePage;
