// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

//import { collection, addDoc } from 'firebase/firestore';
//import { setLogLevel } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
 
// Initialize Firestore and Storage
const db = getFirestore(app);
const storage = getStorage(app);

const exampleCourses = {
    "UCLA": {
        "CS": ["31", "32", "33", "35L", "111", "118", "131", "M151B"],
        "MATH": ["31A", "31B", "32A", "32B", "33A", "33B", "115A", "131A"],
        "CHEM": ["14A", "14B", "14C", "14D", "20A", "20B", "30A", "30B"],
        "PHYSICS": ["1A", "1B", "1C", "4AL", "4BL", "110A", "110B", "115A"]
    },
    "UW": {
        "CSE": ["121", "122", "123", "143", "311", "312", "331", "332"],
        "MATH": ["124", "125", "126", "307", "308", "324", "327", "328"],
        "CHEM": ["142", "152", "162", "237", "238", "239", "241", "242"],
        "PHYS": ["121", "122", "123", "224", "225", "227", "228", "334"]
    },
    "Cal": {
        "COMPSCI": ["61A", "61B", "61C", "70", "161", "162", "164", "169"],
        "MATH": ["1A", "1B", "53", "54", "55", "110", "113", "128A"],
        "CHEM": ["1A", "1B", "3A", "3B", "4A", "4B", "120A", "120B"],
        "PHYSICS": ["7A", "7B", "7C", "89", "137A", "137B", "141A", "141B"]
    }
};

const addExampleCoursesToFirestore = async () => {
    for (const school in exampleCourses) {
        const schoolData = exampleCourses[school];
        await addDoc(collection(db, "courses"), {
            [school]: schoolData
        });
    }
};

// Uncomment the line below to run the function and add the example courses to Firestore
// addExampleCoursesToFirestore();

export { db, storage, addExampleCoursesToFirestore };


